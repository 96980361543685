<template>
  <div class="favorites">
    <h1>Suosikkien vertailu</h1>
    <button>Tulosta</button>
    <button>Jaa</button>
    <div class="list">
      <div
        class="favorite"
        v-for="favorite in favorites"
        :key="favorite.licensePlateNumber"
      >
        {{ favorite.licensePlateNumber }}
        <button @click="removeFavorite(favorite.licensePlateNumber)">
          Poista suosikeista
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      favorites: "favorites/getFavorites"
    })
  },
  methods: {
    removeFavorite(favorite) {
      this.$store.commit("favorites/REMOVE_FAVORITE", favorite);
    }
  },
  mounted() {}
};
</script>

<style></style>
